import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  PageHeader,
  BoxIntroDecor,
  BoxPoster,
  BoxPosterInverse,
  Poster,
} from "../components/styles"

import styled from "styled-components"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

export const GridResponsive = styled(Grid)`
  ${({ theme }) => `
    ${theme.breakpoints.up("md")} {
      & > .MuiGrid-item:first-child {
        order: 2;
      }
      & > .MuiGrid-item:last-child {
        order: 1;
      }
    }
  `}
`

export const posterImage = graphql`
  fragment posterImage on File {
    childImageSharp {
      fluid(maxWidth: 488, maxHeight: 564) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const query = graphql`
  query {
    poster: file(relativePath: { eq: "previous/poster.jpg" }) {
      ...posterImage
    }
    section1: file(relativePath: { eq: "previous/section1.jpg" }) {
      ...posterImage
    }
    section2: file(relativePath: { eq: "previous/section2.jpg" }) {
      ...posterImage
    }
    section3: file(relativePath: { eq: "previous/section3.jpg" }) {
      ...posterImage
    }
  }
`
const Previous = ({ data }) => {
  return (
    <Layout>
      <SEO title="Previous" />
      <Grid container alignItems="center" style={{ margin: "0 0 140px 0" }}>
        <Grid item xs={12} md={6}>
          <BoxIntroDecor>
            <PageHeader variant="h1">Previous</PageHeader>
            <Typography>
              Prior to Sewing Pictures, Producer Khoa Do worked in Sydney and Melbourne on a number of productions as producer, writer and director, working with at-risk youth, refugees and migrants to help tell their stories.
            </Typography>
            <Typography>
              These productions now proudly form part of Sewing Pictures' catalogue of films.
            </Typography>
          </BoxIntroDecor>
        </Grid>
        <Grid item xs={12} md={6}>
          <BoxPoster>
            <Poster fluid={data.poster.childImageSharp.fluid} />
          </BoxPoster>
        </Grid>
      </Grid>
      <GridResponsive
        container
        alignItems="center"
        style={{ margin: "0 0 140px 0" }}
      >
        <Grid item xs={12} md={6}>
          <BoxIntroDecor>
            <PageHeader variant="h1">The Finished People</PageHeader>
            <Typography>
              Feature. 85 mins. 2003.
            </Typography>
            <Typography>
              Feature developed with at-risk youths in western Sydney.
              Nominated for 3 AFI Awards, 3 Film Critic’s Circle Awards and winner of the IF Independent Spirit Award.
            </Typography>
            <Typography>
              Released nationwide through Dendy Cinemas.
            </Typography>

          </BoxIntroDecor>
        </Grid>
        <Grid item xs={12} md={6}>
          <BoxPosterInverse>
            <Poster fluid={data.section1.childImageSharp.fluid} />
          </BoxPosterInverse>
        </Grid>
      </GridResponsive>
      <Grid container alignItems="center" style={{ margin: "0 0 140px 0" }}>
        <Grid item xs={12} md={6}>
          <BoxIntroDecor>
            <PageHeader variant="h1">Mother Fish</PageHeader>
            <Typography>
              Feature. 100 mins. 2009.
            </Typography>
            <Typography>
              Winner of multiple international awards, screened in Official Competition at the Sydney Film Festival, winner of the CRC Award at the Sydney Film Festival, and screened nationwide as The Sydney Morning Herald’s “Movie of the Week”.
            </Typography>
            <Typography>
              Released nationally by Titan View and sold to World Movies, SBS.
            </Typography>
          </BoxIntroDecor>
        </Grid>
        <Grid item xs={12} md={6}>
          <BoxPoster>
            <Poster fluid={data.section2.childImageSharp.fluid} />
          </BoxPoster>
        </Grid>
      </Grid>
      <GridResponsive
        container
        alignItems="center"
        style={{ margin: "0 0 0 0" }}
      >
        <Grid item xs={12} md={6}>
          <BoxIntroDecor>
            <PageHeader variant="h1">Falling for Sahara</PageHeader>
            <Typography>
              Feature. 90 mins. 2011.
            </Typography>
            <Typography>
              Feature developed with refugees from Ethiopia, Eritrea, Sudan and Somalia living in Melbourne’s housing commission estates.
            </Typography>
            <Typography>
              Premiered at the Melbourne International Film Festival, released as a free educational resource in Victorian schools and acclaimed as “Best Locally Made Film 2011” by The Sunday Age.
            </Typography>
          </BoxIntroDecor>
        </Grid>
        <Grid item xs={12} md={6}>
          <BoxPosterInverse>
            <Poster fluid={data.section3.childImageSharp.fluid} />
          </BoxPosterInverse>
        </Grid>
      </GridResponsive>
    </Layout>
  )
}

export default Previous
